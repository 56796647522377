import React from "react";

import classes from './Intro.module.css';

const intro = (props) => (
    <div className={classes.HomeTitle}>
        <h1>WEB DESIGN AND <br/> DEVELOPMENT</h1>
    </div>
);

export default intro;